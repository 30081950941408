.company-create{
    text-align: center;

    h1{
        margin-bottom: 30px !important;
    }

    button{
        margin-top: 50px;
    }
}