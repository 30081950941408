.locations-grid{
    .location-banner-image{
        min-height: 150px;
        height: 300px;
    }

    &-item{
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, var(--opacity20));
        transition: all var(--animation-speed) ease-in-out;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid rgba(var(--secondary-text-color-rgb), var(--opacity60));

        &:hover{

            box-shadow: -1px 4px 13px 4px rgba(0, 0, 0, var(--opacity50));
            transform: translate(3px, -3px);

            .avatar-block{
                padding: 10px !important;
                background-color: var(--primary-bg-color) !important;

                .avatar-image{
                    transform: scale(1) !important;
                }

                .avatar-content{
                    margin-left: 10px !important;

                }
            }

        }

        .alert-notification{
            position: absolute;
            top: -100%;
            left: 50%;
            z-index: 1;
            padding: 5px 15px;
            border-radius: 0 0 5px 5px;
            transform: translate(-50%, 0);
            color: var(--primary-text-color);
            background-color: var(--error-color);
            transition: all var(--animation-speed) ease-in-out;
        }

        &.alert{
            .alert-notification{
                top: 0;
            }
        }

    }

    .grid-header{
        display: block;
        position: relative;
        color: var(--primary-text-color);

        .image-wrap{
            border: none !important;
        }

        .avatar-block{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0 10px;
            overflow: hidden;
            transition: all var(--animation-speed) ease-in-out;
            background-color: rgba(var(--secondary-text-color-rgb), var(--opacity60)) !important;

            .avatar-image{
                transition: all var(--animation-speed) ease-in-out;
                transform: scale(0);
            }

            .avatar-content{
                min-width: 1px;
                margin-left: -45px;
                transition: all var(--animation-speed) ease-in-out;
            }

            h4{
                margin-bottom: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

            }

            .status{
                text-transform: uppercase;
                font-size: 11px;
                line-height: 1em;
                font-weight: bold;

                &.draft{
                    color: var(--warning-color);
                }

                &.active{
                    color: var(--success-color);
                }

                &.closed{
                    opacity: var(--opacity50);
                }

                &.block{
                    color: var(--error-color);
                }
            }
        }
    }

    .grid-data{
        border-top: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity50));
        background-color: rgba(var(--primary-text-color-rgb), var(--opacity20));
        padding: 10px 15px;

        .visits{
            text-transform: lowercase;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            b{
                font-size: 16px;
                margin-left: 10px;
            }
            .anticon{
                font-size: 18px;
            }
        }

    }
}