.visits-page{
    .block{
        top: 0 !important;
    }
}

.company-page{
    h1{
        margin-bottom: 50px;
    }

    .my-profile-form,
    .change-password-form{
        max-width: 450px;
    }

    .company-my-profile-sub-page{
        h1 + p{
            margin-bottom: 25px;
        }
    }

    &-external{
        padding: 0 25px !important;

        & > * {
            display: flex !important;
            justify-content: center !important;
        }

        .company-select-sub-page{
            margin-top: 15%;

            h1{
                text-align: center;
            }
        }

    }
}


.company-create-sub-page{
    margin-top: 10%;

    h1{
        margin-bottom: 40px;
    }

    .ant-form {
        width: 700px;

        .field-group{
            margin-bottom: 24px;
        }

        textarea{
            resize: none;
        }
    }

    .form-actions{
        margin-top: 40px;
    }
}

.company-edit-sub-page {
    textarea {
        resize: none;
    }
}

.location-page{
    h1{
        margin-bottom: 40px;
    }

    .location-qr-wrap{
        margin-top: 50px;
        max-width: 150px;

        button{
            display: block;
            background-color: transparent;
            padding: 0;
            border: initial;
            cursor: pointer;
            margin-bottom: 5px;

            img{
                width: 100%;
            }
        }

    }

    .location-short-info{
        padding-bottom: 40px;

        .avatar-content{
            min-width: 1px;
        }

        h4{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 1px;
            margin-bottom: 0;
        }

        .status{
            text-transform: uppercase;
            font-size: 11px;
            line-height: 1.4em;
            font-weight: bold;

            &.draft{
                color: var(--warning-color);
            }

            &.active{
                color: var(--success-color);
            }

            &.closed{
                opacity: var(--opacity50);
            }

            &.block{
                color: var(--error-color);
            }
        }
    }
}

