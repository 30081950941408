.company-selector{
    margin-top: 30px;
    max-width: 500px;

    .ant-select{
        min-width: 300px;
        width: 100%;
    }

    .action{
        margin-top: 50px;

        .ant-btn{
            width: 100%;
        }
    }
}