@import 'scss/vendors/include-media';

.registration-step{
    margin-bottom: 20px;
    min-height: 400px;

    &.legal{
        min-height: 500px;
    }

    @include media('height<=540px') {
        .step-actions,
        .form-actions{
            position: initial !important;
            margin: 0 !important;
        }

    }

    h1{
        margin-bottom: 24px;
    }

    .legal-modal-wrap{
        margin: 30px 0 60px;
    }

    .icons-set{
        margin: 24px 0;
    }

    &.success{
        .row{
            margin-top: 60px;
        }
    }

    @include media('<=phone') {
        .step-actions,
        .form-actions{
            position: fixed;
            bottom: 20px;
            margin: 0 20px;
            left: 0;
            right: 0;
        }
    }
}

.registration-form{
    @include media('height<=605px') {
        margin-top: 70px;
    }

    .steps-nav{
        justify-content: flex-end;

        &-wrap{
            position: relative;
            top: -50px;

            @include media('<=phone') {
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                margin: 10px 25px;
            }
        }
    }
}

.form-holder{

    h1{
        margin-bottom: 30px;
    }

}

.registration-step,
.forgot-password{
    text-align: left;

    .ant-btn{
        max-width: 320px;


        @include media('<=phone') {
            width: 100%;
            max-width: initial;
        }
    }

    .legal-modal-wrap{
        justify-content: center;
        display: flex;
    }

    .step-actions,
    .form-actions{
        display: flex;
        justify-content: flex-end;

        @include media('<=phone') {
            justify-content: center;
        }
    }
}

.forgot-password{
    .row{
        margin-top: 60px;
    }

    &-message{
        p{
            margin-top: 10px;

            &:first-child{
                margin-top: auto;
            }
        }

        a{
            margin-top: 20px;
            display: block;
        }
    }

    &.change{
        form{
            margin-top: 40px;
        }
    }
}

.sign-in-form{
    text-align: left;

    .form-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .login-form-link{
        text-decoration: underline;

        &:hover{
            color: var(--active-text-color);
        }
    }
}