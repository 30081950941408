.location-banner{
    &-image{
        min-height: 300px;
        position: relative;
        height: 400px;

        &.empty{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity20));
        }

        &.block{
            img{
                filter: blur(6px);
            }
        }

        &.closed{
            img{
                filter: sepia(1) ;
            }
        }

        &.draft{
            img{
                filter:  grayscale(1);
            }
        }


        .image-wrap{
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 0 solid  var(--active-bg-color);
            transition: all 0.2s ease-in-out;

            img{
                will-change: filter;
            }

            &.loading{
                border-width: 2px;

                img{
                    opacity: 0;
                }
            }

            .loader-item{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 5;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 1;
                transition: all var(--animation-speed) ease-in-out;
            }

        }
    }

    &-upload{
        position: absolute;
        right: 20px;
        bottom: 20px;

        .ant-btn{
            max-height: 36px;
            display: flex;
            align-items: center;
            padding:  0 20px;

            .loader-item{
                margin-right: 10px;

                .anticon{
                    font-size: 20px !important;
                }
            }
        }
    }
}