.location-visits{

    &-filter{
        margin-bottom: 24px;

        .row{
            display: flex;
            justify-content: space-between;

            & + .row{
                margin-top: 18px;
            }
        }

        .search-input-wrap{
            max-width: 400px;
            width: 100%;
        }

        .user-type-wrap{
            max-width: 200px;
            width: 100%;
        }

        .covid-wrap{
            display: flex;
            align-items: flex-end;
        }

        .data-range-wrap{
            display: flex;
        }

        .ant-select{
            width: 100%;
        }

        .label-wrap{
            margin-bottom: 0 !important;
        }
    }

    &-table{
        .ant-table{
            background-color: transparent;
            color: var(--primary-text-color);

            .covid-col{
                .ant-btn{
                    margin: 0 auto;
                    width: 35px;
                    height: 35px;
                    font-size: 22px;

                    &.approved{
                        background-color: var(--warning-color) !important;

                        .anticon{
                            margin-left: 1px;
                        }
                    }

                    &.create{
                        transition: all var(--animation-speed) ease-in-out;
                        opacity: 0;

                        .anticon{
                            margin-left: 1px;
                        }
                    }
                }
            }

            tr{
                &:hover{

                    td{
                        background-color: rgba(var(--primary-text-color-rgb), var(--opacity80) ) !important;
                        color: var(--secondary-text-color) !important;
                    }

                    .ant-btn.create{

                        opacity: 1;
                    }
                }
            }

            th{
                color: var(--primary-text-color) !important;
                text-transform: uppercase;
                background-color: transparent;
                text-align: center;

                .ant-table-column-sorter{
                    .active{
                        color: var(--active-text-color) !important;
                    }
                }

                &.ant-table-column-sort{
                    background-color: rgba(var(--primary-text-color-rgb), var(--opacity30) );

                }

                &:hover{

                    &.ant-table-column-has-sorters{
                        background-color: rgba(0, 0, 0, var(--opacity20) ) !important;
                    }

                }
            }

            td{
                transition: all var(--animation-speed) ease-in-out;

                &.ant-table-column-sort{
                    background-color: rgba(var(--primary-text-color-rgb), var(--opacity10) );
                }
            }



            .ant-empty-description{
                color: var(--primary-text-color) !important;
            }

            &-pagination{
                margin-top: 40px !important;

                li{
                    background-color: transparent !important;
                    transition: all var(--animation-speed) ease-in-out;

                    a{
                        color: var(--primary-text-color) !important;
                        transition: all var(--animation-speed) ease-in-out;
                    }

                    &.ant-pagination-item-active,
                    &:hover{
                        border-color: var(--primary-button-bg-color) !important;

                        a{
                            color: var(--secondary-button-color) !important;
                        }
                    }
                }

                button{
                    background-color: transparent !important;
                    color: var(--primary-text-color) !important;

                    &:hover{
                        color: var(--secondary-button-color) !important;
                        border-color: var(--primary-button-bg-color) !important;
                    }
                }

                .ant-pagination-disabled{
                    button{

                        &:hover{
                            color: rgba(var(--active-bg-color-rgb), var(--opacity60)) !important;
                            border-color: rgba(var(--active-bg-color-rgb), var(--opacity60)) !important;
                        }

                    }
                }
            }
        }
    }
}