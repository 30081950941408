.avatar-image{

    &.with-image > .ant-avatar{
        border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity20));
        background-color: var(--primary-button-color);

    }

    .ant-avatar{
        background-color: var(--primary-button-color);
        color: var(--secondary-text-color);
        display: flex;
        align-items: center;
        cursor: pointer;

        img{
            object-fit: contain;
        }
    }

    &.no-image{
        & > .ant-avatar{
            color: var(--sub-text-color);
            background: var(--secondary-bg-color);
            border: none;
        }
    }

    .ant-avatar-square{
        border-radius: 4px;
    }
}