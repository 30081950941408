@import 'scss/vendors/include-media';

.main-menu{
    font-size: 24px;
    line-height: 1.1em;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &-wrap{
        max-width: 300px;
        margin: 0 auto;
        position: relative;
        top: 15%;

        @include media('height<=605px') {
            position: initial;
        }
    }

    a{
        display: block;
        padding: 6px 10px;
        color: var(--secondary-text-color);

        &:hover{
            color: var(--active-text-color);
        }
    }


    .ant-btn{
        font-size: 24px;
        line-height: 1.1em;
        padding: 6px 10px;
        width: 100% !important;
        display: block;
        background-color: transparent;
        height: auto;
        text-transform: initial;
        color: var(--secondary-text-color);
        font-family: inherit;
        font-weight: normal;

        &:focus{
            color: var(--secondary-text-color);
        }

        &:hover{
            color: var(--active-text-color);
        }

    }
}